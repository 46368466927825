<template>
  <div class="maxBox">
    <!-- 顶部通用 -->
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar ">
        <Navbar :itemIndex="activeIndex" />
      </div>
    </div>
    <!-- banner区域 -->
    <div class="boxContentBg">
      <div class="banner"></div>
    </div>
    <!-- 主新闻区域  -->
    <div class="boxContentNews">
      <!-- 新闻区域 -->
      <div class="newsContnet">
        <!-- 滚动轮播区域 -->
        <div class="newsCarousel">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="item in items"
                :key="item.itemId"
              >
                <img :src="item.itemUrl" alt="" />
              </div>
            </div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev">
              <img
                style="width:100%;height:100%"
                src="../../assets/special/atlas_previous@2x.png"
                alt=""
              />
            </div>
            <div class="swiper-button-next">
              <img
                style="width:100%;height:100%"
                src="../../assets/special/atlas_next@2x.png"
                alt=""
              />
            </div>
          </div>
          <div class="newsDescrice">
            <div class="newsDescriceBook">
              <img src="../../assets/special/atlas_title_icon@2x.png" alt="" />
            </div>
            <div class="newsDescriceExplain" @click="totTopicDetails">
              2020中国5G+工业互联网大会“产融合作促进工业互联网生态发展”专题图集
            </div>
            <div class="newsDescricePageNumber">
              <div class="newsPageNew">{{ activeIndexs }}</div>
              <div class="newsLine">/</div>
              <div class="newsPageOld">20</div>
            </div>
          </div>
        </div>
        <!-- 新闻列表区域 -->
        <div class="newsList">
          <div class="newsItem">
            <div class="newsDate">
              <div class="newsDateDay">19</div>
              <div class="newsLine"></div>
              <div class="newsYears">2020-11</div>
            </div>
            <div class="newsDescribe">
              <div class="newsDescribeTop">
                <div class="newsDescribeTopTitle">
                  习近平致2020中国5G+工业互联网大会的贺信
                </div>
                <div class="newsDescribeTopImage">
                  <img
                    src="../../assets/special/news_list_icon@2x.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="newsDescribeBottom">
                值此首届中国5G+工业互联网大会开幕之际，我向大会的召开表示热烈祝贺！
              </div>
            </div>
            <div class="newsSee">
              <div class="newsSeeExplain">查看详情</div>
              <div class="newsSeeArrow">
                <img src="../../assets/special/news_list_more@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="newsItem">
            <div class="newsDate">
              <div class="newsDateDay">20</div>
              <div class="newsLine"></div>
              <div class="newsYears">2020-11</div>
            </div>
            <div class="newsDescribe">
              <div class="newsDescribeTop">
                <div class=" newsDescribeTopTitle">
                  2020中国5G+工业互联网大会“产融合作促进工业互联网生态发展”专题成功举办
                </div>
                <div class="newsDescribeTopImage">
                  <img
                    src="../../assets/special/news_list_icon@2x.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="newsDescribeBottom">
                专题由中国5G+工业互联网大会组委会、工业和信息化部新闻宣传中心、中国工业互联网研究院联合主办。…
              </div>
            </div>
            <div class="newsSee">
              <div class="newsSeeExplain">查看详情</div>
              <div class="newsSeeArrow">
                <img src="../../assets/special/news_list_more@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="newsItem">
            <div class="newsDate">
              <div class="newsDateDay">20</div>
              <div class="newsLine"></div>
              <div class="newsYears">2020-11</div>
            </div>
            <div class="newsDescribe">
              <div class="newsDescribeTop">
                <div class="newsDescribeTopTitle">
                  2020中国5G+工业互联网大会召开 构建产融新生态 赋能数字新经济
                </div>
                <div class="newsDescribeTopImage">
                  <img
                    src="../../assets/special/news_list_icon@2x.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="newsDescribeBottom">
                2020中国5G+工业互联网大会“产融合作促进工业互联网生态发展”专题于11月19日在湖北省武汉市中国光谷科技会展中心隆重召开。…
              </div>
            </div>
            <div class="newsSee">
              <div class="newsSeeExplain">查看详情</div>
              <div class="newsSeeArrow">
                <img src="../../assets/special/news_list_more@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="newsItem">
            <div class="newsDate">
              <div class="newsDateDay">20</div>
              <div class="newsLine"></div>
              <div class="newsYears">2020-11</div>
            </div>
            <div class="newsDescribe">
              <div class="newsDescribeTop">
                <div class="newsDescribeTopTitle">
                  2020中国5G﹢工业互联网大会专题论坛今日举办
                </div>
                <div class="newsDescribeTopImage">
                  <img
                    src="../../assets/special/news_list_icon@2x.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="newsDescribeBottom">
                2020中国5G+工业互联网大会“产融合作促进工业互联网生态发展”专题于11月19日在湖北省武汉市中国光谷科技会展中心隆重召开。…
              </div>
            </div>
            <div class="newsSee">
              <div class="newsSeeExplain">查看详情</div>
              <div class="newsSeeArrow">
                <img src="../../assets/special/news_list_more@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="newsItem">
            <div class="newsDate">
              <div class="newsDateDay">20</div>
              <div class="newsLine"></div>
              <div class="newsYears">2020-11</div>
            </div>
            <div class="newsDescribe">
              <div class="newsDescribeTop">
                <div class="newsDescribeTopTitle">
                  肖亚庆：深入学习贯彻习近平总书记重要指示精神
                  大力推动5G+工业互联网融合发展
                </div>
                <div class="newsDescribeTopImage">
                  <img
                    src="../../assets/special/news_list_icon@2x.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="newsDescribeBottom">
                11月20日，工业和信息化部、湖北省人民政府联合主办的2020中国5G+工业互联网大会在武汉开幕。工业和信息化部党组书记、部长肖亚庆出席开…
              </div>
            </div>
            <div class="newsSee">
              <div class="newsSeeExplain">查看详情</div>
              <div class="newsSeeArrow">
                <img src="../../assets/special/news_list_more@2x.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部通用 -->
    <div class="boxContentFooter">
      <FooterAll />
    </div>
  </div>
</template>

<script>
import Navbar from '../../components/public/NavBar'
import FooterAll from '../../components/public/footerAll'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  data() {
    return {
      activeIndex: '',
      items: [
        {
          itemId: 1,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 2,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 3,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 4,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 5,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 6,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 7,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 8,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 9,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 10,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 11,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 12,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 13,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },

        {
          itemId: 14,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 15,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 16,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 17,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 18,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 19,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
        {
          itemId: 20,
          itemUrl: require('../../assets/special/atlas_img.png'),
        },
      ],
      activeIndexs: 1
    }
  },
  components: {
    Navbar,
    FooterAll,
  },
  created() {
    this.activeIndex = this.$route.query.id
  },
  mounted() {
    const _this = this;
    const mySwiper = new Swiper('.swiper-container', {
      autoplay:true,
      
      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        slideChange: function() {
          _this.activeIndexs = this.activeIndex + 1
        }
        
      }
    })
    mySwiper.el.onmouseover = function(){
      // 鼠标进入，停止自动切换
      mySwiper.autoplay.stop();
    }
    mySwiper.el.onmouseout = function(){
      // 鼠标移出，开始自动切换    
      mySwiper.autoplay.start()
    }
  },
  methods: {
    totTopicDetails() {
      this.$router.push('/atasPage')
    },
  },
}
</script>
<style lang="scss" scoped>
@import './currencyTopic.scss';
.el-carousel__item {
  width: 100%;
  height: 100%;
  > img {
    width: 100%;
    height: 100%;
  }
}
.swiper-container {
  width: 1180px;
  height: 662px;
}
.swiper-slide {
  width: 100%;
  height: 100%;
  > img {
    width: 100%;
    height: 100%;
  }
}
.swiper-button-prev {
  width: 48px;
  height: 48px;
  position: absolute;
  left: 20px;
  top: 307px;
  z-index: 1;
  > img {
    @extend .imgFill;
  }
}
.swiper-button-next {
  width: 48px;
  height: 48px;
  position: absolute;
  right: 20px;
  top: 307px;
  > img {
    @extend .imgFill;
  }
}
.swiper-button-next:after {
  opacity: 0;
}
.swiper-button-prev::after {
  opacity: 0;
}
</style>
